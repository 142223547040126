import React, { useContext } from "react"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

import { 
  Box, 
  Button, 
  Container, 
  Flex, 
  Heading,
  Text,
  useBreakpointValue,
  Grid, 
  GridItem
} from "@chakra-ui/react"

import { motion } from "framer-motion";
import FramerInViewFade from "../components/framer-inview-fade.js"

import { ModalContext } from "../components/modal-context.js"

import Layout from "../components/layout"

export const HomepageContent = (props) => {

    const post = props.post;

    const isMdBreakpoint = useBreakpointValue({ base: false, md: true });
    
    const [activeModal, setModal] = useContext(ModalContext);

    const setModalContact = () => {
      console.log('Opening contact modal');
      setModal('contact');
    }
  
    return (
      <Layout>
        <Container maxW="container.lg">
          <SEO title={post.title} />

          <Flex as="header" minHeight="95vh" flexDirection="column" pt="16">
            <Flex flexDirection="column" mt="auto">
              <StaticImage 
                src="../images/rod-homepage.png" 
                loading="eager"
                objectFit="contain"
                placeholder="none"
                width={506}
                height={272}
                alt="An illustration of two lightbulbs that have turned into two customers talking." 
              />
              <FramerInViewFade delay="0">
                <Heading as="h1" size="2xl" align="center" mb="4" mt="6">
                  Take your idea from concept to customer
                </Heading>
              </FramerInViewFade>
              <FramerInViewFade delay="0.2">
                <Text px={{ base: '0', md: '24', lg: '48' }} align="center" fontSize="lg">                
                  I combine visual identities that resonate, websites that perform, and the backend systems capable of bringing it all together.
                </Text>
              </FramerInViewFade>
              <FramerInViewFade delay="0.4">
                <Box textAlign="center" mt="6">
                  <Button colorScheme="secondary" size="lg" px="8" onClick={setModalContact}>Let’s talk</Button>
                </Box>
              </FramerInViewFade>
            </Flex>
            <Box mt="auto">
              <FramerInViewFade delay="1">
                <Box textAlign="center" mt="6" textTransform="uppercase" letterSpacing="3px" fontWeight="bold" fontSize="sm">
                  Learn more<br/>
                  <FramerInViewFade delay="1.2">
                    <Box display="inline-block" mx="auto" mt="4">
                      <motion.div
                        animate={{ y: 15 }}
                        transition={{ repeat: Infinity, repeatType: "reverse", duration: 2, delay: 1.5 }}
                      >
                        <svg width="15px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="long-arrow-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-long-arrow-down fa-w-8"><path fill="currentColor" d="M252.485 343.03l-7.07-7.071c-4.686-4.686-12.284-4.686-16.971 0L145 419.887V44c0-6.627-5.373-12-12-12h-10c-6.627 0-12 5.373-12 12v375.887l-83.444-83.928c-4.686-4.686-12.284-4.686-16.971 0l-7.07 7.071c-4.686 4.686-4.686 12.284 0 16.97l116 116.485c4.686 4.686 12.284 4.686 16.971 0l116-116.485c4.686-4.686 4.686-12.284-.001-16.97z" class=""></path></svg>
                      </motion.div>
                    </Box>
                  </FramerInViewFade>
                </Box>
              </FramerInViewFade>
            </Box>
          </Flex>
        </Container>

        <Container maxW="container.lg" >  
          <Flex as="section" flexDirection="column" pt={{ base: '12', md: '24' }}>
            <FramerInViewFade>
              <Text pb={{ base: '0' }} align="center" fontSize={{ base: '120px' }} mb="-70px" fontFamily="var(--chakra-fonts-heading)" fontWeight="bold" textAlign="center">
                &ldquo;
              </Text>
            </FramerInViewFade>
            <FramerInViewFade delay="0.2">
              <Text px={{ base: '4', md: '14' }} mb={{ base: '12', md: '24' }} align="center" fontSize={{ base: '2xl', md: '4xl' }} fontFamily="var(--chakra-fonts-heading)">
                From targeted campaigns and visual identities, to back-end systems and website development; I bring the right customers to your business and put the systems in place to keep them there.
              </Text>
            </FramerInViewFade>
          </Flex>
        </Container>

        <Container maxW="container.lg" id="services" px={{ base: '4', md: '8', lg: '4' }}>
          <Box as="section" py={{ base: '24', md: '24' }} mx={{ base: '0', md: '-4' }}>
            <Flex flexDirection={{ base: 'column', md: 'row' }} flexWrap="wrap">
              <Box flex={{base: '1 1 100%', md: '1 1 50%', lg: '1 1 33.33%'}}>
                <FramerInViewFade>
                  <Box px={{ base: '0', md: '4' }} my={{ base: '6'}}>
                    <FramerInViewFade>
                      <Heading as="h2" fontSize={{ base: '2xl', md: '4xl' }} mb="4">Visual <br style={{display: (isMdBreakpoint) ? 'block' : 'none' }} />Identity</Heading>
                    </FramerInViewFade>
                    <FramerInViewFade delay="0.2">
                      <Text as="p" fontSize="lg">A strong visual identity provides the foundation for positive engagement across all of your brand's touchpoints. I provide <b>logos</b>, <b>identities</b> and <b>design systems</b> that clearly communicate the ethos of your brand in a way that is flexible and versatile.</Text>
                    </FramerInViewFade>
                  </Box>
                </FramerInViewFade>
              </Box>
              <Box flex={{base: '1 1 100%', md: '1 1 50%', lg: '1 1 33.33%'}}>
                <FramerInViewFade delay="0.4">
                  <Box px={{ base: '0', md: '4' }} my={{ base: '6'}}>
                    <FramerInViewFade delay="0.4">
                      <Heading as="h2" fontSize={{ base: '2xl', md: '4xl' }} mb="4">UX/UI <br style={{display: (isMdBreakpoint) ? 'block' : 'none' }} />Design</Heading>
                    </FramerInViewFade>
                    <FramerInViewFade delay="0.6">
                      <Text as="p" fontSize="lg">Understanding your user and <b>structuring your content</b> appropriately in key to any businesses. Using best practices, research and continuous monitoring I design website that captivate, inform and generate revenue. I've also a particular interest in designing <b>high converting landing pages</b>.</Text>
                    </FramerInViewFade>
                  </Box>
                </FramerInViewFade>
              </Box>
              <Box flex={{base: '1 1 100%', md: '1 1 50%', lg: '1 1 33.33%'}}>
                <FramerInViewFade delay="0.6" px={{ base: '0', md: '4' }}>
                  <Box px={{ base: '0', md: '4' }} my={{ base: '6'}}>
                    <FramerInViewFade delay="0.6">
                      <Heading as="h2" fontSize={{ base: '2xl', md: '4xl' }} mb="4">Website <br style={{display: (isMdBreakpoint) ? 'block' : 'none' }} />Development</Heading>
                    </FramerInViewFade>
                    <FramerInViewFade delay="0.8">
                      <Text as="p" fontSize="lg">I've developed numerous <b>plugins and themes</b> for <b>WordPress</b> websites, that enrich user experience and achieve business goals. I've also built web apps with <b>React</b> and static sites using <b>Gatsby</b> for smooth, lightning-fast experiences.</Text>
                    </FramerInViewFade>
                  </Box>
                </FramerInViewFade>
              </Box>
              <Box flex={{base: '1 1 100%', md: '1 1 50%', lg: '1 1 33.33%'}}>
                <FramerInViewFade>
                  <Box px={{ base: '0', md: '4' }} my={{ base: '6'}}>
                    <FramerInViewFade>
                      <Heading as="h2" fontSize={{ base: '2xl', md: '4xl' }} mb="4">CRM & <br style={{display: (isMdBreakpoint) ? 'block' : 'none' }} />Integrations</Heading>
                    </FramerInViewFade>
                    <FramerInViewFade delay="0.2">
                      <Text as="p" fontSize="lg">I've developed extensively with CRM systems including <b>Salesforce</b> and <b>Zoho CRM</b>. By building custom functionality and integrations I've cut down on admin time, human error and streamlined day to day business activities, saving both time and money.</Text>
                    </FramerInViewFade>
                  </Box>
                </FramerInViewFade>
              </Box>
              <Box flex={{base: '1 1 100%', md: '1 1 50%', lg: '1 1 33.33%'}}>
                <FramerInViewFade delay="0.4">
                  <Box px={{ base: '0', md: '4' }} my={{ base: '6'}}>
                    <FramerInViewFade delay="0.4">
                      <Heading as="h2" fontSize={{ base: '2xl', md: '4xl' }} mb="4">Search Engine & <br style={{display: (isMdBreakpoint) ? 'block' : 'none' }} />PPC Advertising</Heading>
                    </FramerInViewFade>
                    <FramerInViewFade delay="0.6">
                      <Text as="p" fontSize="lg">Over the last few years, I have generating several million pounds in revenue across <b>Google and Bings Ads</b>. By combining the right audiences, messaging and visuals with high converting landing pages, I can lead your customers through each stage of your sales funnel.</Text>
                    </FramerInViewFade>
                  </Box>
                </FramerInViewFade>
              </Box>
              <Box flex={{base: '1 1 100%', md: '1 1 50%', lg: '1 1 33.33%'}}>
                <FramerInViewFade delay="0.6" px={{ base: '0', md: '4' }}>
                  <Box px={{ base: '0', md: '4' }} my={{ base: '6'}}>
                    <FramerInViewFade delay="0.6">
                      <Heading as="h2" fontSize={{ base: '2xl', md: '4xl' }} mb="4">Customer <br style={{display: (isMdBreakpoint) ? 'block' : 'none' }} />Retention</Heading>
                    </FramerInViewFade>
                    <FramerInViewFade delay="0.8">
                      <Text as="p" fontSize="lg">Maximise the revenue from your existing customer base. By monitoring key activities across channels, I can put in place strategies to cross-sell products, lower subscription churn or re-engage lapsed customers.</Text>
                    </FramerInViewFade>
                  </Box>
                </FramerInViewFade>
              </Box>
            </Flex>        
          </Box>
        </Container>

        <Container maxW="container.lg" id="about">
          <Box as="section" py={{ base: '44', md: '44' }} >
            <Grid
              templateColumns="repeat(12, 1fr)"
              gap={4}
            >
              <GridItem colStart={{ base: '1', md: '3' }} colEnd={{ base: '13', md: '11' }} textAlign="center">
                <FramerInViewFade delay="0.2">
                  <Heading as="h2" size="2xl" mb="8" lineHeight="1.2">Uniquely positioned to make maximum impact</Heading>
                </FramerInViewFade>
                <FramerInViewFade delay="0.4">
                  <Box fontSize="lg">
                    <Text as="p" mb="4">
                      By working hands on in design, development and marketing I gain a unique understanding of businesses and the underlying technology that makes them tick. It enables me to tackle problems before they arise and plan the best possible action for any given scenario.
                    </Text>
                    <Text as="p" mb="4">
                      I've worked with start-ups and small to medium businesses in varying markets including Hong Kong, Australia and the UK, with audiences ranging from students, all the way up to ultra-high net worth individuals. 
                    </Text>
                    <Text as="p" mb="4">
                      Every client is unique, and I find nothing more satisfying than overcoming the challenges presented by each individual case.
                    </Text>
                  </Box>
                </FramerInViewFade>
              </GridItem>
            </Grid>
          </Box>
        </Container>

        <Container maxW="container.lg">
          <Box as="section" pt={{ base: '24', md: '24' }} pb={{ base: '72', md: '72' }} textAlign="center">
            <FramerInViewFade>
              <Heading as="h2" size="2xl" align="center" mb="4">Let’s start something</Heading>
            </FramerInViewFade>
            <FramerInViewFade  delay="0.2">
              <Text as="p" px={{base: '0', md: '36'}} fontSize="lg">
                Whether your project is large or small, I'd love to talk about what we can do together.
              </Text>
            </FramerInViewFade>
            <FramerInViewFade  delay="0.4">
              <Box textAlign="center" mt="6">
                <Button colorScheme="secondary" size="lg" px="8" onClick={setModalContact}>Let’s talk</Button>
              </Box>
            </FramerInViewFade>
          </Box>
        </Container>

      </Layout>
    )

}

export default HomepageContent