import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

export function FramerInViewFade(props) {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);
  
    return (
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ 
            duration: 1,
            delay: props.delay,
            ease: "easeOut"
        }}
        variants={{
          visible: { opacity: 1, scale: 1, y: 0 },
          hidden: { opacity: 0, scale: 1, y: 30 }
        }}
      >
        {props.children}
      </motion.div>
    );
}

export default FramerInViewFade;